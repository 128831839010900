import clsx from "clsx"
import { useId } from 'react'


export function CallToAction() {
    return (
        <>

            <section
                id="get-free-shares-today"
                className="relative overflow-hidden bg-gray-400 py-20 sm:py-28"
            >
                <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
                    <CircleBackground color="#fff" className="animate-spin-slower" />
                </div>
                <Container className="relative">
                    <div className="mx-auto max-w-md sm:text-center">
                        <h2 className="text-3xl font-lexend font-bold tracking-tight text-white sm:text-4xl">
                            Take a breath
                        </h2>
                        <p className="mt-4 text-lg text-gray-100">
                            Forked makes all of those things easier.
                        </p>
                        {/* <div className="mt-8 flex justify-center">
                            <Button className="mt-10 bg-white font-lexend !text-md">
                                Get 14 days free
                            </Button>
                        </div> */}
                    </div>
                </Container>
            </section>
        </>)
}

export function Container({
    className,
    ...props
}: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}
            {...props}
        />
    )
}

export function CircleBackground({
    color,
    ...props
}: React.ComponentPropsWithoutRef<'svg'> & {
    color: string
}) {
    let id = useId()

    return (
        <svg
            viewBox="0 0 558 558"
            width="558"
            height="558"
            fill="none"
            aria-hidden="true"
            {...props}
        >
            <defs>
                <linearGradient
                    id={id}
                    x1="79"
                    y1="16"
                    x2="105"
                    y2="237"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={color} />
                    <stop offset="1" stopColor={color} stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                opacity=".2"
                d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
                stroke={color}
            />
            <path
                d="M1 279C1 125.465 125.465 1 279 1"
                stroke={`url(#${id})`}
                strokeLinecap="round"
            />
        </svg>
    )
}
