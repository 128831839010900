import { useLoaderData, useNavigate } from "@remix-run/react";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import CheckIcon from "~/components/ui/icons/CheckIcon";
import { useRootData } from "~/utils/data/useRootData";
import { Container } from "../CallToAction";
import Carousel from "../Carousel";

export default function Headline() {
  const rootData = useRootData();
  const navigate = useNavigate();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);
  const data = useLoaderData();
  const { images, recipes } = data ?? {};

  const recipeImages = useMemo(() => {
    return (
      recipes?.map((recipe) => {
        return { src: recipe.imageFilename, alt: recipe.name, url: `/recipe/${recipe.urlId}/${recipe.urlKey}` };
      }) ?? []
    );
  }, [recipes]);

  const loggedIn = useMemo(() => {
    return rootData.hasSub;
  }, [rootData.hasSub]);

  const handleEnter = useCallback(() => {
    if (loggedIn) {
      navigate("/app");
    } else {
      openAccount("click", "free_trial");
    }
  }, [loggedIn, navigate, openAccount]);

  const features = [
    {
      name: "Fork your favorite recipes.",
      description: "It's like saving, but better! Save, modify, add notes, share, schedule... ",
      icon: CheckIcon,
    },
    {
      name: "Create Recipe Collections.",
      description: "A great way to organize your recipes. You can even share them with friends.",
      icon: CheckIcon,
    },
    {
      name: "Schedule your meals.",
      description: "Eliminate weekday stress. Plan recipes you want to make this week.",
      icon: CheckIcon,
    },
    {
      name: "Shop smarter.",
      description:
        "Once you plan a recipe, we'll add the ingredients to your smart shopping list.  We'll let you know if any items are already in your pantry.",
      icon: CheckIcon,
    },
  ];

  const includedFeatures = [
    'Unlimited Recipes',
    'Smart Shopping Lists',
    'Meal Planning',
    'Premium Support',
    'Unlimited Collections',
    'Ad-Free Experience',
  ]
  return (
    <>
      <Feature features={features} images={images} title={"Forked makes it easy"} />
      <section
        className="relative overflow-hidden bg-theme-300 py-10"
      >
        {/* <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
          <CircleBackground color="#fff" className="animate-spin-slower" />
        </div> */}
        <Container className="relative">
          <div className="mx-auto max-w-md sm:text-center">
            <h2 className="text-2xl font-lexend font-bold tracking-tight text-white">
              And did we mention?
            </h2>
            <h2 className="text-5xl pt-4 font-lexend font-bold tracking-tight text-white">
              No Ads
            </h2>

            {/* <div className="mt-8 flex justify-center">
                            <Button className="mt-10 bg-white font-lexend !text-md">
                                Get 14 days free
                            </Button>
                        </div> */}
          </div>
        </Container>
      </section>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-lexend font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Yes, we know you want it free, and if we could we would offer that. But your savings will far outweigh the cost.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Monthly Membership</h3>

              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-theme-600">What’s included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-theme-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-theme-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">Monthly Cost</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">$4.95</span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                  </p>
                  <div
                    onClick={handleEnter}
                    className="mt-10 block w-full rounded-md bg-theme-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
                  >
                    Get Forked
                  </div>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Cancel anytime. No hidden fees. No contracts. No surprises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex max-w-4xl flex-col items-center justify-center pb-8 pt-2 text-center">
        {/* <h1 className="font-lexend text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          The recipe app for people who <br />
          love cooking & hate ads
        </h1> */}
        {/* <h1 className="font-lexend text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          When you just <span className="italic tracking-tighter">forking</span> want a recipe
        </h1> */}
        {/* <p className="mt-6 max-w-2xl text-lg leading-8 text-gray-600">
          Stop scrolling endlessly through recipe sites and a myriad of ads. Save the recipes you love, from anywhere on the web.
        </p> */}
        {/* <div className="mt-10 flex items-center justify-center gap-x-6">
          <div
            className="rounded-md bg-theme-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
            onClick={handleEnter}
          >
            Let's Go!
          </div>
        </div> */}
        {/* <p className="text-md mt-6 leading-8 text-gray-600">Sign-up is as easy as logging into your email. Oh, and everyone gets 14 days free!</p> */}

      </div>
      {/* <div className="flex items-center items-center justify-center gap-x-6 bg-white px-6 py-2.5 sm:px-3.5 ">
        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div className="rounded-2xl bg-theme-100 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div className="mx-auto max-w-xs px-8">
              <p className="text-base font-semibold text-gray-600">14-day Free Trial</p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">$4.95</span>
                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/month</span>
              </p>
              <div
                onClick={handleEnter}
                className="mt-10 block w-full rounded-md bg-theme-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-600"
              >
                Get access
              </div>
              <p className="mt-6 text-xs leading-5 text-gray-600">Sign-up is as easy as logging into your email.</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl ">
          <div className="tracking-tigh text-center font-lexend text-xl font-bold leading-8 text-gray-900 py-4">Check out some of our members' latest forks!</div>
          {/* <div className="mt-10 grid w-full grid-flow-col items-center gap-x-8 gap-y-10 overflow-x-auto px-8 scrollbar-hide sm:gap-x-10 lg:max-w-none py-6">
            {recipeImages.map((recipeImage) => {
              return (
                <Link to={recipeImage.url} key={recipeImage.src} title={recipeImage.alt} className="w-36">
                  <img
                    className="w-full rounded-lg object-cover shadow-lg"
                    src={`${recipeImage.src}?class=aspect11`}
                    alt={recipeImage.alt}
                    title={recipeImage.alt}
                    loading="lazy"
                  />
                </Link>
              );
            })}
          </div> */}
          <Carousel images={recipeImages} />
        </div>
      </div>

    </>
  );
}

export function Feature({ title, subtitle, features, images, light = false, left = false }) {
  return (
    <div className={clsx("flex-none overflow-hidden  py-20", light ? "bg-theme-100" : "bg-theme-600")}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className={clsx("lg:pt-4", left ? "lg:ml-auto lg:pl-4" : "lg:pr-8")}>
            <div className="lg:max-w-lg">
              {/* <h2 className="text-base font-semibold leading-7 text-white">Find anything you can dream of</h2> */}
              <p className={clsx("mt-2 text-3xl font-bold tracking-tight sm:text-4xl", light ? "text-slate-800" : "text-white")}>{title}</p>
              <p className={clsx("mt-6 text-lg leading-8", light ? "text-slate-800" : "text-white")}>
                {subtitle}
              </p>
              <div className={clsx("mt-10 max-w-xl space-y-8 text-base leading-7  lg:max-w-none", light ? "text-slate-800" : "text-theme-100")}>
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-12">
                    <div className={clsx("inline font-lexend font-semibold tracking-tight", light ? "text-slate-800" : "text-white")}>
                      <feature.icon className="absolute left-1 top-1 h-10 w-10" color1={clsx(light ? "fill-theme-600" : "fill-white")} color2={clsx(light ? "fill-white" : "fill-theme-600")} aria-hidden="true" />
                      <h3 className="text-lg font-bold">{feature.name}</h3>
                    </div>
                    <div className="inline">{feature.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={clsx("flex ", left ? "lg:order-first items-start justify-end" : "lg:order-last")}>

            <img
              src={images && Array.isArray(images) ? `${images[0]}?width=912&height=570` : null}
              alt="Forked Recipe App Screenshot"
              title="Forked Recipe App Screenshot"
              className={clsx("w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0", left ? "order-first" : "order-last", left ? "mr-8" : "")}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function FeatureNew() {
  return (<div className="overflow-hidden bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className={clsx("lg:pt-4", left ? "lg:ml-auto lg:pl-4" : "lg:pr-8")}>
          <div className="lg:max-w-lg">
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{title}</p>
            <p className="mt-6 text-lg leading-8 text-white">
              {subtitle}
            </p>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className={clsx("flex items-start justify-end", left ? "lg:order-first" : "lg:order-last")}>

          <img
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>

      </div>
    </div>
  </div>)
}

export function FeaturesLeft() {
  return (<div className="overflow-hidden bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
          <div className="lg:max-w-lg">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
              iste dolor cupiditate blanditiis ratione.
            </p>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className={clsx("flex items-start justify-end", left ? "lg:order-first" : "lg:order-last")}>
          <img
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  </div>)
}